<template>
	<div class="card card-custom card-stretch gutter-b" v-if="solarseason">
		<div class="card-header border-0" v-if="name">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Solar Transit Events</h3>
		</div>
		<div class="card-body px-5 pt-0">
			<div class="row">
				<div class="col-md-6">
					<p class="text-center">Remote</p>
				</div>
				<div class="col-md-6">
					<p class="text-center">Teleport</p>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6 fade-element-in">
					<table class="table table-striped table-hover">
						<thead>
							<tr>
								<td>Date</td>
								<td>Start Time (UTC)</td>
								<td>End Time (UTC)</td>
								<td>Duration (mm:ss)</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(sr, i) in solarremote" v-bind:key="i">
								<td>{{ sr[0] }}</td>
								<td>{{ sr[1] }}</td>
								<td>{{ sr[2] }}</td>
								<td>{{ sr[3] }}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="col-md-6 fade-element-in">
					<table class="table table-striped table-hover">
						<thead>
							<tr>
								<td>Date</td>
								<td>Start Time (UTC)</td>
								<td>End Time (UTC)</td>
								<td>Duration (mm:ss)</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(sr, i) in solarteleport" v-bind:key="i">
								<td>{{ sr[0] }}</td>
								<td>{{ sr[1] }}</td>
								<td>{{ sr[2] }}</td>
								<td>{{ sr[3] }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Calculate } from '@/view/content/lib/solar.js';
export default {
	name: 'SolarTransitComtech',
	props: ['name', 'properties'],
	data() {
		return {
			solarseason: false,
			solarremote: null,
			solarteleport: null,
		};
	},
	mounted() {
		var y = new Date().getFullYear();
		this.solarseason = false;
		// months start at 0 for new Date
		if (Date.now() >= new Date(y, '1', '1') && Date.now() <= new Date(y, '4', '30')) {
			this.solarseason = 'Vernal';
		} else if (Date.now() >= new Date(y, '7', '8') && Date.now() <= new Date(y, '10', '1')) {
			this.solarseason = 'Autumnal';
		}

		if (this.solarseason) {
			if (
				this.properties.remote.lat !== '' &&
				this.properties.remote.antsize !== ''
			) {
				this.solarremote = eval(
					'(' +
						Calculate(
							this.properties.remote.lat,
							this.properties.remote.lon,
							this.properties.remote.satlon,
							this.solarseason == 'Vernal' ? 1 : 0,
							new Date().getFullYear(),
							this.properties.remote.antsize,
							this.properties.remote.band == 'C' ? 1 : 0
						) +
						')'
				);
			} else {
				this.solarremote = null;
			}
			if (
				this.properties.hub.lat !== '' &&
				this.properties.remote.antsize !== ''
			) {
				this.solarteleport = eval(
					'(' +
						Calculate(
							this.properties.hub.lat,
							this.properties.hub.lon,
							this.properties.hub.satlon,
							this.solarseason == 'Vernal' ? 1 : 0,
							new Date().getFullYear(),
							this.properties.hub.antsize,
							this.properties.hub.band == 'C' ? 1 : 0
						) +
						')'
				);
			} else {
				this.solarteleport = null;
			}
		}
	},
};
</script>

<style>
</style>